var render = function render(){
  var _vm$exhibition, _vm$exhibition$photo, _vm$exhibition2, _vm$exhibition3, _vm$exhibition4, _vm$exhibition5, _vm$exhibition6, _vm$exhibition6$durat, _vm$exhibition7, _vm$exhibition7$durat, _vm$exhibition8, _vm$exhibition8$durat, _vm$exhibition8$durat2, _vm$exhibition8$durat3, _vm$exhibition9, _vm$exhibition9$durat, _vm$exhibition9$durat2, _vm$exhibition9$durat3;
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('client-page', [_c('page-section', {
    staticClass: "page-section--first pb-0"
  }, [_c('template', {
    slot: "tit"
  }, [_vm._v(_vm._s(_vm.groupText))]), _c('v-card', {
    attrs: {
      "img": (_vm$exhibition = _vm.exhibition) === null || _vm$exhibition === void 0 ? void 0 : (_vm$exhibition$photo = _vm$exhibition.photo) === null || _vm$exhibition$photo === void 0 ? void 0 : _vm$exhibition$photo.url
    }
  }, [_c('v-responsive', {
    attrs: {
      "aspect-ratio": _vm.$vuetify.breakpoint.lgAndUp ? 1330 / 500 : 300 / 180,
      "content-class": "d-flex justify-center align-center"
    }
  }, [_c('v-overlay', {
    attrs: {
      "absolute": "",
      "z-index": "1"
    }
  }), _c('div', {
    staticClass: "w-100 px-12px py-30px pa-md-40px py-lg-60px text-center white--text position-relative z-1"
  }, [_c('div', {
    staticClass: "mb-8px"
  }, [(_vm$exhibition2 = _vm.exhibition) !== null && _vm$exhibition2 !== void 0 && _vm$exhibition2.subtitle ? _c('v-chip', {
    staticClass: "exhibition-subtitle",
    attrs: {
      "color": "secondary"
    }
  }, [_vm._v(_vm._s((_vm$exhibition3 = _vm.exhibition) === null || _vm$exhibition3 === void 0 ? void 0 : _vm$exhibition3.subtitle))]) : _vm._e()], 1), (_vm$exhibition4 = _vm.exhibition) !== null && _vm$exhibition4 !== void 0 && _vm$exhibition4.title ? _c('div', {
    staticClass: "tit tit--sm mb-8px mb-md-12px"
  }, [_vm._v(_vm._s((_vm$exhibition5 = _vm.exhibition) === null || _vm$exhibition5 === void 0 ? void 0 : _vm$exhibition5.title))]) : _vm._e(), (_vm$exhibition6 = _vm.exhibition) !== null && _vm$exhibition6 !== void 0 && (_vm$exhibition6$durat = _vm$exhibition6.duration) !== null && _vm$exhibition6$durat !== void 0 && _vm$exhibition6$durat[0] || (_vm$exhibition7 = _vm.exhibition) !== null && _vm$exhibition7 !== void 0 && (_vm$exhibition7$durat = _vm$exhibition7.duration) !== null && _vm$exhibition7$durat !== void 0 && _vm$exhibition7$durat[1] ? _c('div', {
    staticClass: "txt txt--sm white--text"
  }, [_vm._v(_vm._s(((_vm$exhibition8 = _vm.exhibition) === null || _vm$exhibition8 === void 0 ? void 0 : (_vm$exhibition8$durat = _vm$exhibition8.duration) === null || _vm$exhibition8$durat === void 0 ? void 0 : (_vm$exhibition8$durat2 = _vm$exhibition8$durat[0]) === null || _vm$exhibition8$durat2 === void 0 ? void 0 : (_vm$exhibition8$durat3 = _vm$exhibition8$durat2.toDate) === null || _vm$exhibition8$durat3 === void 0 ? void 0 : _vm$exhibition8$durat3.call(_vm$exhibition8$durat2)) || "") + " ~ " + _vm._s(((_vm$exhibition9 = _vm.exhibition) === null || _vm$exhibition9 === void 0 ? void 0 : (_vm$exhibition9$durat = _vm$exhibition9.duration) === null || _vm$exhibition9$durat === void 0 ? void 0 : (_vm$exhibition9$durat2 = _vm$exhibition9$durat[1]) === null || _vm$exhibition9$durat2 === void 0 ? void 0 : (_vm$exhibition9$durat3 = _vm$exhibition9$durat2.toDate) === null || _vm$exhibition9$durat3 === void 0 ? void 0 : _vm$exhibition9$durat3.call(_vm$exhibition9$durat2)) || ""))]) : _vm._e(), _vm.mainProducts.length ? _c('div', {
    staticClass: "pt-20px pt-md-40px"
  }, [_c('v-row', {
    attrs: {
      "justify": "center"
    }
  }, _vm._l(_vm.mainProducts, function (product) {
    var _product$thumb, _product$thumb2;
    return _c('v-col', {
      key: product._id,
      attrs: {
        "cols": "auto"
      }
    }, [_c('v-card', {
      attrs: {
        "flat": "",
        "to": `/shop/products/${product._id}`
      }
    }, [_c('v-img', {
      staticClass: "grey",
      attrs: {
        "cover": "",
        "width": "120",
        "height": "120",
        "src": product === null || product === void 0 ? void 0 : (_product$thumb = product.thumb) === null || _product$thumb === void 0 ? void 0 : _product$thumb.url
      }
    }, [_c('v-fade-transition', [_c('v-overlay', {
      directives: [{
        name: "show",
        rawName: "v-show",
        value: !(product !== null && product !== void 0 && (_product$thumb2 = product.thumb) !== null && _product$thumb2 !== void 0 && _product$thumb2.url),
        expression: "!product?.thumb?.url"
      }],
      attrs: {
        "absolute": ""
      }
    }, [_c('v-icon', [_vm._v("mdi-image-broken")])], 1)], 1)], 1)], 1)], 1);
  }), 1)], 1) : _vm._e()])], 1)], 1)], 2), _c('page-section', {
    staticClass: "page-section--sm page-section--last"
  }, [_c('v-row', {
    staticClass: "product-items"
  }, _vm._l(_vm.items, function (item) {
    return _c('shop-product-item', {
      key: item._id,
      attrs: {
        "value": item
      }
    });
  }), 1), _c('div', {
    staticClass: "pagination-wrap"
  }, [_c('v-pagination', {
    attrs: {
      "value": _vm.page,
      "length": _vm.pageCount,
      "total-visible": 11,
      "color": "primary"
    },
    on: {
      "input": function (page) {
        return _vm.$router.push({
          query: Object.assign({}, _vm.$route.query, {
            page
          })
        });
      }
    }
  })], 1)], 1)], 1);

}
var staticRenderFns = []

export { render, staticRenderFns }