var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('client-page', [_c('page-section', {
    staticClass: "page-section--first page-section--last"
  }, [_c('template', {
    slot: "tit"
  }, [_vm._v(_vm._s(_vm.groupText))]), _vm.items.length ? [_c('v-row', _vm._l(_vm.items, function (item, index) {
    var _item$thumb;
    return _c('v-col', {
      key: item._id,
      attrs: {
        "cols": "12"
      }
    }, [_c('v-card', {
      attrs: {
        "to": `${_vm.$route.path}/${item._id}`
      }
    }, [_c('v-img', {
      attrs: {
        "cover": "",
        "src": item === null || item === void 0 ? void 0 : (_item$thumb = item.thumb) === null || _item$thumb === void 0 ? void 0 : _item$thumb.url,
        "aspect-ratio": _vm.$vuetify.breakpoint.lgAndUp ? 1330 / 340 : 300 / 100
      }
    }, [_c('div', {
      staticClass: "d-flex align-center h-100 px-20px py-20px py-md-40px px-lg-60px",
      staticStyle: {
        "background-color": "rgba(0, 0, 0, 0.5)"
      }
    }, [_c('div', {
      staticClass: "white--text"
    }, [item !== null && item !== void 0 && item.label ? _c('v-chip', {
      staticClass: "font-weight-bold",
      attrs: {
        "small": "",
        "pill": "",
        "color": index % 2 === 0 ? 'primary' : 'secondary'
      }
    }, [_vm._v(_vm._s(item === null || item === void 0 ? void 0 : item.label))]) : _vm._e(), _c('div', {
      staticClass: "font-size-22 font-size-sm-32 font-size-lg-48 mt-8px"
    }, [_vm._v(_vm._s(item === null || item === void 0 ? void 0 : item.title))]), item !== null && item !== void 0 && item.summary ? _c('div', {
      staticClass: "font-size-12 font-size-md-16 mt-10px mt-sm-14px"
    }, [_vm._v(_vm._s(item === null || item === void 0 ? void 0 : item.summary))]) : _vm._e()], 1)])])], 1)], 1);
  }), 1), _c('div', {
    staticClass: "pagination-wrap"
  }, [_c('v-pagination', {
    attrs: {
      "value": _vm.page,
      "length": _vm.pageCount,
      "total-visible": 11,
      "color": "primary"
    },
    on: {
      "input": function (page) {
        return _vm.$router.push({
          query: Object.assign({}, _vm.$route.query, {
            page
          })
        });
      }
    }
  })], 1)] : !_vm.loading ? [_c('div', {
    staticClass: "text-center"
  }, [_c('div', {
    staticClass: "mb-18px mb-md-24px"
  }, [_c('v-icon', {
    attrs: {
      "size": "60px",
      "color": "primary"
    }
  }, [_vm._v("mdi-calendar-star")])], 1), _c('div', {
    staticClass: "tit tit--sm"
  }, [_vm._v("등록된 " + _vm._s(_vm.groupText) + " 항목이 없습니다.")]), _c('div', {
    staticClass: "txt txt--sm mt-8px"
  }, [_vm._v("상품 목록을 확인해주세요.")]), _c('div', {
    staticClass: "btn-wrap btn-wrap--lg"
  }, [_c('v-row', {
    staticClass: "row--sm",
    attrs: {
      "justify": "center"
    }
  }, [_c('v-col', {
    attrs: {
      "cols": "6",
      "sm": "auto"
    }
  }, [_c('v-btn', _vm._b({
    staticClass: "v-size--xx-large w-100 min-w-sm-200px",
    attrs: {
      "to": "/shop"
    }
  }, 'v-btn', Object.assign({}, _vm.btn_primary, _vm.$attrs), false), [_vm._v("쇼핑 계속하기")])], 1)], 1)], 1)])] : _vm._e()], 2)], 1);

}
var staticRenderFns = []

export { render, staticRenderFns }